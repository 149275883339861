.footer-main {
	background: $color-white;
	border-top: 1px solid $color-borders;
	padding: 60px 0;

	@include mq(tablet-down) {
		padding: 40px 0;
	}


	.sub-call-to-action {
		@extend %container;

		display: flex;

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-details {
			flex: 1;

			@include mq(tablet-down) {
				text-align: center;
			}


			& > span {
				display: block;
				font-size: $font-size-large;
				margin-bottom: 30px;

				@include mq(tablet-down) {
					font-size: 19px;
					max-width: 100%;
					margin: 0 auto 30px;
				}
			}

			& > ul {
				@extend %list-default;

				& > li {
					display: inline-block;
					margin-right: 35px;

					@include mq(tablet-down) {
						margin-bottom: 10px;
						margin-right: 0;
					}


					strong {
						font-size: $font-size-large;
						font-weight: $font-weight-bold;
						margin-right: 10px;
					}

					a {
						font-size: $font-size-larger;
					}

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}

		.sub-logos {
			flex-basis: 340px;

			@include mq(tablet-down) {
				flex-basis: auto;
			}


			& > ul {
				@extend %list-default;

				display: flex;
				margin-bottom: 10px;

				@include mq(tablet-down) {
					margin-top: 30px;
					justify-content: center;
				}


				& > li {
					align-items: center;
					display: flex;
					flex: 1;

					@include mq(tablet-down) {
						flex: none;

						&:first-child {
							margin-right: 20px;
						}
					}


					&:last-child {
						justify-content: flex-end;
					}
				}
			}

			p {
				font-weight: $font-weight-light;
				margin-bottom: 0;

				@include mq(tablet-down) {
					text-align: center;
				}
			}
		}
	}

	.sub-distr-text {
		@extend %container;

		@include mq(tablet-down) {
			text-align: center;
		}
	}

	.sub-legal {
		@extend %container;

		margin-top: 40px;

		.list-legal {
			@extend %list-default;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			& > li {
				align-self: center;
				flex: 0 0 23%;

				&.item-links {
					flex-basis: 50%;

					@include mq(tablet-down) {
						flex-basis: 100%;
						order: 3;
						text-align: center;
					}


					ul {
						@include mq(tablet-down) {
							display: block;

							& > li {
								display: block;

								&:last-child, &:nth-last-child(2) {
									display: inline-block;
								}

								span {
									color: $color-text;
									pointer-events: none;
									text-decoration: none;
								}
							}
						}
					}

					p {
						margin-bottom: 0;

						br {
							display: none;

							@include mq(tablet-down) {
								display: inline-block;
							}
						}
					}
				}

				&.item-logos ul {
					display: flex;
					align-items: center;

					li {
						&:nth-child(2) {
							margin: 0 40px;
						}
					}
				}

				&:nth-child(2) {
					@include mq(tablet-down) {
						margin: 45px 0;
						order: 1;
					}
				}

				ul {
					@extend %list-default;

					li {
						display: inline-block;
					}

					&.list-social {
						display: flex;

						li {
							display: inline-flex;
							margin-right: 12px;

							&:last-child(2) {
								margin: 0;
							}

							a {
								align-items: center;
								background: #9a9a9a;
								border-radius: 50%;
								display: flex;
								height: 37px;
								justify-content: center;
								text-align: center;
								width: 37px;

								svg {
									color: $color-white;

									&.icon-facebook {
										width: 9px;
									}

									&.icon-twitter {
										width: 25px;
									}

									&.icon-linkedin {
										width: 18px;
									}

									&.icon-youtube {
										width: 22px;
										height: 18px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
