/* COLOURS */
$color-black: #000000;
$color-white: #ffffff;

$color-body: $color-white;
$color-body-grey: #F3F3F5;

$color-neutral: #B4B4B4;
$color-neutral-dark: #96969B;

$color-borders: #DCDCDC;
$color-borders-light: $color-body-grey;

$color-text: #1E1E1E;
$color-text-light: #7D7D7D;

$color-headings: $color-text;

$color-error: #ff0000;

$color-primary: #EB212E;
$color-secondary: #039BE5;

$color-buttons: $color-primary;

$color-link: $color-primary;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

$color-validation-error: #cd0000;
$color-validation-success: #4eb73c;

/* FONTS */
$font-standard: "proxima-nova", Helvetica, Sans-serif;

$font-base: 14;

$font-size-base: $font-base * 1px;
$font-size-standard: 14 / $font-base * 1rem;
$font-size-tiny: 10 / $font-base * 1rem;
$font-size-smaller: 12 / $font-base * 1rem;
$font-size-small: 13 / $font-base * 1rem;
$font-size-small-med: 15 / $font-base * 1rem;
$font-size-med: 16 / $font-base * 1rem;
$font-size-med-large: 17 / $font-base * 1rem;
$font-size-large: 18 / $font-base * 1rem;
$font-size-larger: 22 / $font-base * 1rem;
$font-size-largest: 33 / $font-base * 1rem;

$font-size-h1: 36 / $font-base * 1rem;
$font-size-h2: 30 / $font-base * 1rem;
$font-size-h3: 26 / $font-base * 1rem;
$font-size-h4: $font-size-large;
$font-size-h5: $font-size-med;
$font-size-h6: $font-size-standard;

$font-size-hero-standard: $font-size-larger;
$font-size-hero-small: 38 / $font-base * 1rem;
$font-size-hero-med: 48 / $font-base * 1rem;
$font-size-hero-large: 80 / $font-base * 1rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;
$font-weight-bolder: 800;
$font-weight-black: 900;

$line-height-small: 21px;
$line-height-base: 24px;
$line-height-med: 26px;
$line-height-large: 30px;
$line-height-larger: 37px;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-bolder;
$headings-color: $color-headings;
$headings-line-height: $line-height-base;

$radius-button: 0;
$padding-button: 12px;
$text-size-button: $font-size-smaller;

/* STRUCTURE */
$viewport-width: 100%;
$container-width: 90%;
$container-max-width: 1240px;
$container-max-width-small: 1170px;
$container-max-width-large: 1300px;

/* MOBILE STRUCTURE */
$container-width-mobile: 100%;
$container-gutter-mobile: 20px;
$container-max-width-mobile: calc(100% - #{$container-gutter-mobile});

/* MEDIA QUERY */
$mq-desktop-size: $container-max-width;
$mq-tablet-size: 1024px;
$mq-mobile-size: 768px;
