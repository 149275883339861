#contact {
	.section-hero {
		@extend %common-hero-darker;

		height: 287px;

		@include mq(mobile) {
			height: 177px;
			overflow: hidden;

			&:after {
				bottom: -64px;
				height: 100px;
				transform: skewY(-4deg);
			}
		}


		img {
			height: auto;
			width: 100%;

			@include mq(mobile) {
				height: 100%;
				width: auto;
			}
		}

		&:before {
			content: '';
			background: $color-black;
			height: 100%;
			left: 0;
			opacity: 0.3;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.sub-content {
			@extend %container-small;

			@include mq(mobile) {
				margin-top: 0;
			}
		}
	}

	.sub-contact-copy {
		align-items: center;
		display: flex;
		margin-top: -80px;
		max-width: none !important;
		position: relative;
		z-index: 9;

		@include mq(tablet-down) {
			display: none;
		}


		h1 {
			margin-bottom: 0;
			margin-right: 75px;
		}

		&.mod-mobile {
			display: none;

			@include mq(tablet-down) {
				align-items: flex-start;
				align-self: center;
				display: flex;
				flex-direction: column;
				margin: 20px auto 0 !important;
				max-width: calc(100% - 20px) !important;
			}


			@include mq(mobile) {
				align-self: unset;
				margin-top: 50px;
			}


			h1 {
				@include mq(tablet-down) {
					margin-bottom: 30px;
				}


				@include mq(mobile) {
					margin-bottom: 115px;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}

	.sub-call, .sub-email {
		color: $color-white;
		font-size: $font-size-h3;
		flex: 1;
		z-index: 99;

		@include mq(tablet-down) {
			color: $color-black;
			font-size: $font-size-larger;
		}
	}

	.sub-email {
		color: $color-primary;

		@include mq(tablet-down) {
			color: $color-primary;
			margin-top: 10px;
		}


		span {
			color: $color-white;

			@include mq(tablet-down) {
				color: $color-black;
			}
		}
	}

	.sub-span {
		display: inline-block;
		font-size: $font-size-larger;
		font-weight: $font-weight-bold;
		margin-right: 10px;

		@include mq(tablet-down) {
			font-size: $font-size-large;
		}
	}

	.section-form {
		@extend %container;

		padding-bottom: 80px;
		padding-top: 50px;

		@include mq(tablet-down) {
			flex-direction: column;
			padding-bottom: 50px;
			padding-top: 50px !important;
			width: 98% !important;
		}


		@include mq(mobile) {
			padding-top: 130px;
		}


		form {
			margin: 0 auto;
			max-width: 690px;
			width: 100%;

			@include mq(tablet-down) {
				margin-right: 0;
				order: 1;
				padding-top: 0;
				max-width: none;
			}


			h2 {
				font-size: 20px;
				font-weight: $font-weight-black;
				margin-bottom: 45px;

				@include mq(mobile) {
					margin-bottom: 30px;
				}
			}

			.form-element:first-of-type {
				margin-top: 0;
			}

			.btn {
				display: inline-block;
				padding: 0;
				position: relative;
				text-align: left;
				transition: background .3s ease;

				&.mod-green {
					background: $color-validation-success;
					border: 1px solid darken($color-validation-success, 10%);
					pointer-events: none;
				}

				.sub-circle {
					animation: fadein 1s ease forwards;
					border-radius: 50%;
					border: 1px solid $color-white;
					height: 27px;
					opacity: 0;
					position: absolute;
					right: 8px;
					top: 6px;
					width: 27px;

					&:after {
						animation: checkmark 1s ease 800ms forwards;
						border-right: 2px solid $color-white;
						border-top: 2px solid $color-white;
						content: '';
						height: 14px;
						left: 3px;
						opacity: 0;
						position: absolute;
						top: 14px;
						transform-origin: left top;
						transform: scaleX(-1) rotate(135deg);
						width: 7px;
					}
				}
			}
		}

		.sub-thanks {
			@extend %container;

			display: none;
			max-width: 690px !important;
			padding: 100px 0 250px;

			h3 {
				line-height: 36px;
				margin-bottom: 0;
			}
		}
	}

	.section-map {
		display: flex;
		height: 645px;
		position: relative;

		@include mq(tablet-down) {
			display: block;
			height: auto;
			flex-direction: column;
		}


		&:before {
			@extend %diagonal;

			top: -100px;
			z-index: 99;

			@include mq(tablet-down) {
				top: -40px;
			}


			@include mq(mobile) {
				z-index: 99;
				height: 70px;
			}
		}

		.sub-directions {
			align-items: center;
			background: $color-text;
			display: flex;
			flex-basis: 555px;
			flex-direction: column;
			justify-content: center;
			padding-top: 65px;

			@include mq(tablet-down) {
				display: block;
				flex-basis: auto;
				height: auto;
				padding: 140px 0 130px;
			}


			@include mq(mobile) {
				padding: 70px 0 50px;
			}


			.sub-copy {
				position: relative;

				@include mq(tablet-down) {
					text-align: center;
					width: 100%;
				}


				@include mq(mobile) {
					padding: 55px 10px 40px;
					text-align: left;
				}


				&:after {
					@include mq(mobile) {
						background: #1d1d1d;
						bottom: -16px;
						content: '';
						height: 60px;
						left: -3px;
						position: absolute;
						transform: rotate(-4deg);
						width: 100.4%;
						z-index: 99;
					}
				}

				.mod-desktop {
					@include mq(tablet-down) {
						display: none;
					}


					@include mq(mobile) {
						display: block;
						margin-bottom: 15px;

						strong {
							font-weight: $font-weight-bold;
						}
					}
				}

				.mod-mobile {
					display: none;

					@include mq(tablet-down) {
						display: block;
					}


					@include mq(mobile) {
						display: none;
					}
				}

				h3 {
					color: $color-white;
					font-size: 20px;
					font-weight: $font-weight-bolder;
					margin-bottom: 28px;
					text-transform: uppercase;

					@include mq(tablet-down) {
						margin-bottom: 15px;
					}


					@include mq(mobile) {
						margin-bottom: 25px;
					}
				}

				p {
					color: $color-white;
					font-size: $font-size-small-med;
					line-height: $line-height-small;

					@include mq(tablet-down) {
						margin: 0 auto;
						max-width: 275px;
						text-align: center;
					}


					@include mq(mobile) {
						margin: 0;
						max-width: none;
						text-align: left;
					}
				}

				.btn {
					display: inline-block;
					font-size: $font-size-smaller;
					margin-top: 25px;
					padding: 0;

					@include mq(tablet-down) {
						bottom: -80px;
						font-size: $font-size-med;
						font-weight: $font-weight-light;
						max-width: 267px;
						padding: 13px 20px;
						left: 50%;
						position: absolute;
						transform: translateX(-50%);
						z-index: 9;
					}


					@include mq(mobile) {
						bottom: 0;
						font-size: $font-size-small;
						left: auto;
						font-weight: $font-weight-bold;
						padding: 0;
						position: relative;
						transform: none;
					}
				}
			}
		}

		#map {
			flex: 1;

			@include mq(tablet-down) {
				display: block;
				flex: none;
				height: 385px;
			}


			@include mq(mobile) {
				height: 470px;
			}
		}
	}

	.grecaptcha-badge {
		bottom: 110px !important;
		visibility: visible !important;
		z-index: 9999;
	}
}
