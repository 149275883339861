#gas {
	background: $color-body-grey;

	.section-hero {
		@extend %common-hero-darker;

		&:after {
			background: $color-body-grey;
		}
	}

	.section-gases {
		@extend %container-small;

		padding-bottom: 50px;

		@include mq(mobile) {
			padding-bottom: 10px;
		}


		.list-gases {
			@extend %list-default;

			display: flex;
			padding: 35px 0;

			@include mq(mobile) {
				padding: 5px 0 0px;
				flex-direction: column;
			}


			& > li {
				flex: 1;
				margin: 0 20px;

				@include mq(tablet-down) {
					margin: 0 10px 0 0;
				}


				@include mq(mobile) {
					margin: 0 0 45px;
				}


				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				h3 {
					@extend %common-subheading;

					font-size: 20px;
					margin-bottom: 25px;
				}
			}

			.list-sub-gases {
				@extend %list-default;

				& > li {
					background: $color-white;
					height: 50px;
					font-weight: $font-weight-semibold;
					margin-bottom: 6px;
					position: relative;

					a {
						align-items: center;
						color: $color-text;
						display: flex;
						height: 100%;
						padding: 0 18px;
						transition: all .3s ease;
						width: 100%;

						&:after {
							@extend %caret-right;

							right: 18px;
							top: 50%;
							transition: right .3s ease;
							transform: translateY(-50%);
						}

						&:hover {
							box-shadow: 0 2px 15px 0 rgba($color-black, 0.25);
							color: $color-primary;

							&:after {
								right: 14px;
							}
						}
					}
				}
			}
		}
	}

	.section-search-gas {
		align-items: center;
		display: flex;
		height: 347px;
		overflow: hidden;
		position: relative;

		@include mq(tablet-down) {
			display: none;
		}


		img {
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.sub-content {
			@extend %container;

			position: relative;
			z-index: 9;

			h4 {
				color: $color-white;
				font-size: 34px;
				font-weight: $font-weight-black;
				margin-bottom: 30px;
				text-transform: uppercase;
			}

			& > span, & > a {
				color: rgba($color-white, 0.5);
				display: block;
				font-size: 20px;
				font-weight: $font-weight-light;
				margin-bottom: 5px;
			}

			a {
				color: $color-primary;
			}

			.sub-keyword-wrap {
				position: relative;

				form {
					margin-top: 35px;
					position: relative;

					input {
						background: none;
						border: none;
						border-bottom: 1px solid $color-primary;
						color: $color-white;
						font-size: 24px;
						max-width: 630px;
						padding-bottom: 20px;
						width: 100%;

						&::placeholder {
							color: $color-white;
						}
					}
				}

				.sub-results {
					-ms-overflow-style: none;
					align-items: center;
					background: $color-white;
					box-shadow: 0px 0px 10px rgba($color-black, 0.2);
					display: flex;
					flex-direction: column;
					left: 0;
					max-height: 300px;
					max-width: 630px;
					overflow: scroll;
					position: absolute;
					top: 52px;
					width: 100%;

					.sub-error.mod-pad {
						padding: 20px;
					}

					.list-results {
						@extend %list-default;

						-ms-overflow-style: none;
						align-self: flex-start;
						display: none;
						max-height: 500px;
						padding: 10px 20px;
						overflow: scroll;
						width: 100%;

						&.mod-show {
							display: block;
						}

						& > li {
							display: flex;

							&:first-child {
								padding-top: 0;
							}

							&:last-child a, &:only-child a {
								border-bottom: none;
							}

							.title {
								border-bottom: 1px solid rgba($color-black, 0.25);
								color: $color-text;
								display: block;
								flex: 1;
								font-size: $font-size-med;
								padding: 9px 0;

								&:hover {
									color: $color-primary;
								}

								@include mq(mobile) {
									padding-right: 40px;
								}
							}
						}
					}
				}
			}
		}
	}

	&.subpage {
		background: $color-white;

		.section-hero {
			height: 287px;

			@include mq(mobile) {
				height: 177px;
			}


			&:after {
				background: $color-white;
			}
		}

		.section-information {
			@extend %container;

			padding: 70px 0 0;

			@include mq(mobile) {
				padding-top: 5px;
			}


			.sub-copy {
				max-width: 965px;

				h2 {
					@extend %common-subheading;

					font-size: 20px;
					margin-bottom: 15px;
				}

				p {
					color: rgba($color-text, 0.6);
					font-size: $font-size-small-med;
				}
			}
		}

		.section-product-listing {
			padding: 60px 0;

			@include mq(tablet-down) {
				padding: 35px 0;
			}


			h3 {
				@extend %common-subheading;

				font-size: 20px;
				margin-bottom: 45px;
				max-width: none;

				@include mq(tablet-down) {
					margin-bottom: 35px;
				}
			}

			.sub-content {
				@extend %container;

				.list-products {
					@extend %product-listing;

					& > li {
						width: 22.5%;
						margin-right: 2%;

						@include mq(mobile) {
							width: 48%;

							&:nth-child(2n+2) {
								margin-right: 0;
							}
						}


						&:nth-child(3n+3) {
							margin-right: 2%;
						}

						&:nth-child(4n) {
							margin-right: 0;
						}
					}
				}
			}
		}

		.section-gases {
			background: $color-body-grey;
			max-width: none;
			padding: 70px 0 55px;
			width: 100%;

			@include mq(mobile) {
				padding: 65px 10px 0;
			}


			.list-gases {
				@extend %container-small;

				max-width: 1110px;
			}
		}
	}

	.section-table {
		padding: 60px 0;

		@include mq(mobile) {
			overflow: scroll;
		}


		.sub-content {
			@extend %container;

			table {
				border-color: $color-borders;

				tr {
					&:hover td {
						background: rgba($color-neutral, 0.2);
					}

					td {
						border-color: $color-borders;
						padding: 10px;
						transition: all .3s ease;
					}

					&:first-child {
						td {
							background: $color-black;
							color: $color-white;
							font-weight: $font-weight-semibold;
						}
					}

					&:nth-child(2n+2) {
						background: $color-white;
					}
				}
			}
		}
	}
}
