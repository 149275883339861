#home {
	.section-hero {
		height: 576px;
		overflow: visible;
		position: relative;

		@include mq(tablet-down) {
			height: auto;
		}


		&:after {
			@extend %diagonal;

			@include mq(tablet-down) {
				content: none;
			}
		}

		.sub-bg {
			background-repeat: no-repeat;
			background-size: cover;
			display: flex;
			height: 100%;
			left: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			width: 100%;

			@include mq(tablet-down) {
				height: 335px;
				overflow: hidden;
				position: relative;
			}


			.sub-image {
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				transform: scale(1.1);
				width: auto;
			}

			&:after {
				@extend %diagonal;

				content: none;

				@include mq(tablet-down) {
					bottom: -60px;
					content: '';
					transform: skewY(-4deg);
				}


				@include mq(mobile) {
					bottom: -70px;
					transform: skewY(-6deg);
				}
			}
		}

		.sub-content {
			@extend %container;

			align-self: center;
			display: flex;
			margin: -90px auto 0;
			padding-right: 65px;
			z-index: 9;

			@include mq(tablet-down) {
				margin: -25px auto 0;
			}


			.sub-copy {
				align-self: center;
				display: flex;
				flex: 1;
				flex-direction: column;
				padding-right: 20px;

				@include mq(mobile) {
					padding-right: 24vw;
				}


				h1 {
					font-size: $font-size-hero-large;
					font-weight: $font-weight-bolder;
					line-height: 1;
					margin-bottom: 5px;
					text-transform: uppercase;

					@include mq(tablet-down) {
						font-size: 40px;
						line-height: $line-height-larger;
						margin-bottom: 15px;
					}
				}

				h2 {
					color: rgba($color-text, 0.8);
					font-size: $font-size-hero-small;
					line-height: $line-height-larger;
					max-width: 650px;
					margin-bottom: 25px;
					text-transform: uppercase;
					width: 100%;

					@include mq(tablet-down) {
						font-size: $font-size-med-large;
						line-height: 19px;
						margin-bottom: 5px;
					}
				}

				p {
					font-size: $font-size-larger;
					line-height: $line-height-large;
					max-width: 560px;
					width: 100%;

					@include mq(tablet-down) {
						display: none;
					}
				}

				.btn {
					font-size: $font-size-standard;
					margin-top: 15px;

					@include mq(tablet-down) {
						font-size: $font-size-smaller;
						margin-top: 0;
						padding-left: 0;
						text-align: left;
					}
				}
			}

			.sub-img {
				align-self: center;
				width: 364px;

				@include mq(tablet-down) {
					align-self: center;
					max-width: 226px;
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-60%);
				}


				@include mq(mobile) {
					max-width: 32vw;
					min-width: 120px;
					right: 5px;
				}
			}
		}

		ul.list-detectors {
			@extend %list-default;

			bottom: -120px;
			box-shadow: 0 2px 10px 0 rgba($color-black, 0.25);
			display: flex;
			height: 220px;
			left: 50%;
			max-width: 940px;
			position: absolute;
			transform: translateX(-50%);
			width: 100%;
			z-index: 99;

			@include mq(tablet-down) {
				bottom: auto;
				height: auto;
				margin-top: -40px;
				max-width: 88%;
				position: relative;
			}


			@include mq(mobile) {
				flex-direction: column;
			}


			li {
				background: $color-white;
				display: flex;

				@include mq(tablet-down) {
					height: 125px;
					position: relative;
				}


				.sub-link {
					@include mq(tablet-down) {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
					}
				}

				&:first-child {
					background: $color-text;
					color: $color-white;

					h3 {
						color: $color-white;
					}

					.sub-copy .sub-text p {
						color: rgba($color-white, 0.5);
					}
				}

				.sub-copy {
					align-self: center;
					display: flex;
					max-width: 95%;
					margin: 0 auto;

					@include mq(tablet-down) {
						margin: 0;
						max-width: none;
						padding: 0 60px 0 10px;
					}


					.sub-text {
						@include mq(tablet-down) {
							align-self: center;
						}


						h3 {
							font-size: $font-size-med;
							font-weight: $font-weight-bolder;
							letter-spacing: 2px;
							margin-bottom: 10px;
							text-transform: uppercase;

							@include mq(tablet-down) {
								font-size: $font-size-small-med;
								line-height: 18px;
								margin-bottom: 0;
							}
						}

						p {
							color: rgba($color-text, 0.8);
							font-size: $font-size-small-med;
							margin-bottom: 10px;

							@include mq(tablet-down) {
								display: none;
							}
						}

						.btn {
							@include mq(tablet-down) {
								font-size: $font-size-smaller;
								padding-bottom: 0;
								padding-top: 12px;
								text-align: left;
							}
						}
					}

					img {
						align-self: flex-start;
						flex: 1;
						height: 70px;
						margin-right: 7px;

						@include mq(tablet-down) {
							align-self: center;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}

	.section-featured-products {
		padding: 175px 0 125px;

		@include mq(tablet-down) {
			padding: 45px 0;
		}


		.sub-content {
			@extend %container;

			header {
				align-items: center;
				display: flex;
				margin-bottom: 45px;

				@include mq(tablet-down) {
					margin-bottom: 35px;
				}


				.sub-title {
					flex: 1;

					h2 {
						@extend %common-subheading;

						margin-bottom: 0;
					}
				}

				a {
					@extend %arrow-transition;

					color: $color-text-light;
					font-size: $font-size-smaller;
					padding-right: 25px;
					position: relative;

					@include mq(mobile) {
						display: none;
					}


					&:hover:after {
						right: -5px;
					}

					&:after {
						@extend %caret-right;

						right: 0;
					}
				}
			}

			.list-featured-products {
				@extend %list-default;

				display: flex;

				@include mq(tablet-down) {
					flex-direction: column;
				}


				& > li {
					background: url("../assets/images/corner-pattern.png") no-repeat right top;
					box-shadow: 0 2px 10px 0 rgba($color-black, 0.25);
					flex: 1;
					height: auto;
					min-height: 263px;
					position: relative;
					transition: all .3s ease;

					@include mq(tablet-down) {
						background-size: 71% 115px;
						height: auto;
						overflow: hidden;
						margin-bottom: 22px;
						min-height: 216px;
					}


					&:nth-child(3n+2) {
						margin: 0 37px;

						@include mq(tablet-down) {
							margin: 0 0 22px;
						}
					}

					&:hover {
						box-shadow: 0 2px 15px 0 rgba($color-black, 0.55);
					}

					.sub-link {
						display: flex;
						height: auto;
						min-height: 263px;
						padding: 20px 0;
					}

					.sub-img {
						height: 100px;
						position: absolute;
						right: 0px;
						top: 5px;
						transition: all .3s ease;

						img {
							height: 100%;
							width: auto;
						}

						@include mq(tablet-down) {
							background-position: 50% bottom;
							height: 120px;
							right: 10px;
							top: 5px;
						}
					}

					.sub-text {
						align-self: flex-end;
						padding: 0 42px 42px 42px;

						@include mq(tablet-down) {
							padding: 0 25px 35px 25px;
						}


						h3 {
							color: $color-primary;
							font-size: $font-size-med;
							font-weight: $font-weight-bolder;
							margin-bottom: 4px;
							text-transform: uppercase;
						}

						h4 {
							font-size: $font-size-small-med;
							font-weight: $font-weight-regular;
							margin-bottom: 10px;
							padding-right: 80px;
						}

						p {
							color: rgba($color-text, 0.6);
							font-size: $font-size-small;
							line-height: $line-height-small;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.section-servicing {
		align-items: center;
		display: flex;
		height: 665px;
		position: relative;

		@include mq(tablet-down) {
			flex-direction: column;
			height: auto;
		}


		.sub-img {
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			overflow: hidden;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;

			@include mq(tablet-down) {
				height: 285px;
				position: relative;
			}


			img {
				height: auto;
				left: 0;
				position: absolute;
				top: 50px;
				transform: scale(1.3);
				width: 110%;
			}
		}

		.sub-content {
			@extend %container;

			display: flex;
			justify-content: flex-end;
			padding-right: 26px;

			@include mq(tablet-down) {
				padding-right: 0;
			}


			.sub-copy {
				background: $color-white;
				clip-path: polygon(100% 100%, 100% 0%, 0% 0%, 0% 85%, 12% 100%);
				height: auto;
				min-height: 420px;
				max-width: 440px;
				padding: 67px 60px;
				width: 100%;

				@include mq(tablet-down) {
					clip-path: none;
					height: auto;
					max-width: none;
					min-height: auto;
					padding: 45px 0px;
					width: 100%;
				}


				h4 {
					font-size: $font-size-large;
					font-weight: $font-weight-bolder;
					letter-spacing: 3px;
					text-transform: uppercase;
				}

				p {
					color: rgba($color-text, 0.6);
					font-size: $font-size-med;
					line-height: $line-height-med;
				}

				.btn {
					margin-top: 15px;

					@include mq(tablet-down) {
						font-size: $font-size-small;
						text-align: left;
					}
				}
			}
		}
	}
}
