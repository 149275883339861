.modal {
	background: rgba($color-white, 0.95);
	height: 100%;
	position: fixed;
	transform: scale(0);
	transition: transform .35s ease;
	top: 0;
	width: 100%;
	z-index: 0;

	&.modal-open {
		transform: scale(1);
		z-index: 999;

		.modal-content {
			opacity: 1;
			visibility: visible;
		}
	}

	.modal-close {
		cursor: pointer;
		height: 37px;
		position: absolute;
		right: 70px;
		top: 25px;
		transition: transform .25s ease;
		width: 40px;

		@include mq(mobile) {
			right: 25px;
		}


		.close-bar-1, .close-bar-2 {
			background-color: $color-primary;
			height: 2px;
			margin-bottom: 12px;
			position: absolute;
			width: 90%;
			transform: translateY(17px) rotate(45deg);

			&.close-bar-2 {
				transform: translateY(17px) rotate(-45deg);
			}
		}

		&:hover {
			transform: rotate(90deg);
		}
	}

	.modal-content {
		background-color: $color-white;
		height: 100%;
		left: 50%;
		opacity: 0;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: opacity .4s ease .3s, visibility .4s ease .3s;
		visibility: hidden;
		width: 65%;

		&.modal-gallery {
			align-items: center;
			display: flex;
			justify-content: center;

			img {
				width: 70%;
				height: 70%;
				object-fit: contain;

				@include mq(mobile) {
					max-width: 80%;
				}
			}
		}

		@include mq(tablet-down) {
			height: 65vw;
			width: 65vw;
		}


		@include mq(mobile) {
			height: 75vw;
			width: 95vw;
		}
	}
}
