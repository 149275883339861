.header-wrap {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;

	.header-main {
		align-items: center;
		background: $color-white;
		display: flex;
		height: 92px;
		padding: 0 23px;
		position: relative;

		@include mq(tablet-down) {
			height: 52px;
			padding: 0 10px;
		}


		.logo {
			flex: 1;
			margin-right: 15px;

			@include mq(tablet-down) {
				display: flex;
				flex: none;
				margin-right: unset;
			}


			svg {
				height: 54px;
				width: 282px;

				@media only screen and (max-width: 1115px) {
					width: 200px;
				}

				@include mq(tablet-down) {
					height: 30px;
					width: 160px;
				}
			}
		}

		.mob-hamburger {
			@include mq(tablet-down) {
				display: flex;
				flex-direction: column;
				height: 19px;
				justify-content: center;
				overflow: hidden;
				position: absolute;
				right: 10px;
				width: 23px;

				.mob-bar {
					background: $color-primary;
					border-radius: 20px;
					display: block;
					height: 2px;
					left: 0;
					margin-bottom: 8px;
					opacity: 1;
					position: absolute;
					transform: rotate(0deg);
					transition: .4s ease-in-out;
					width: 100%;
				}

				.mob-bar-1 {
					top: 0;
				}

				.mob-bar-2 {
					top: 8px;
				}

				.mob-bar-3 {
					margin-bottom: 0;
					top: 16px;
					width: 14px;
				}

				&.mod-active {
					.mob-bar-1 {
						top: 9px;
						transform: rotate(135deg);
					}

					.mob-bar-2 {
						left: -60px;
						opacity: 0;
					}

					.mob-bar-3 {
						transform: rotate(-135deg);
						top: 9px;
						width: 100%;
					}
				}
			}
		}

		#nav {
			display: flex;

			&.mod-desktop {
				@include mq(tablet-down) {
					display: none;
				}
			}

			&.mod-mobile {
				display: none;

				@include mq(tablet-down) {
					display: block;
				}
			}

			@include mq(tablet-down) {
				height: calc(100vh - 52px);
				left: 0;
				position: absolute;
				top: 52px;
				visibility: hidden;
				width: 100%;
			}


			ul.list-nav {
				@extend %list-default;

				align-items: center;
				display: flex;
				flex: 1;
				height: 62px;
				margin-right: 2vw;
				position: relative;

				@media screen and (max-width: 1337px) {
					align-items: flex-start;
					padding-top: 15px;
				}

				@include mq(tablet-down) {
					background: $color-white;
					display: none;
					flex-direction: column;
					height: 100%;
					overflow: scroll;
					margin-right: 0;
					padding: 30px 10px;
					visibility: hidden;
					width: 100%;

					&.mod-active {
						visibility: visible;
					}
				}


				li {
					display: inline-block;
					line-height: 14px;
					margin-right: 25px;

					@include mq(tablet-down) {
						display: block;
						margin-right: 0;
						width: 100%;
					}


					&:first-child {
						display: none;

						@include mq(tablet-down) {
							display: block;
						}
					}

					&:last-child {
						margin-right: 0;
					}

					&.mod-active {
						& > a, & > span {
							color: $color-primary;
						}
					}

					a, & > .mod-dropdown {
						color: $color-text;
						font-size: $font-size-smaller;
						font-weight: $font-weight-bold;
						letter-spacing: 1px;
						text-transform: uppercase;
						transition: color .3s ease;

						&:hover {
							color: $color-primary;

							@include mq(tablet-down) {
								color: $color-text;
							}
						}

						@include mq(tablet-down) {
							align-self: flex-start;
							display: inline-block;
							font-size: 20px;
							font-weight: $font-weight-bolder;
							padding: 17px 20px 17px 0;
							position: relative;
							width: auto;
						}
					}

					&.mod-sub-nav {
						align-items: center;
						display: flex;
						height: 62px;
						cursor: default;
						position: relative;

						@media screen and (max-width: 1337px) {
							align-items: flex-start;
						}

						@include mq(tablet-down) {
							flex-direction: column;
							height: auto;
							overflow: hidden;
						}


						&:hover ul {
							opacity: 1;
							visibility: visible;
							transition: opacity .3s ease;
						}

						.mod-dropdown {
							&:after {
								@include mq(tablet-down) {
									border-bottom: 0;
									border-left: 5px solid transparent;
									border-right: 5px solid transparent;
									border-top: 9px solid $color-primary;
									content: '';
									height: 0;
									position: absolute;
									right: -4px;
									top: 50%;
									transform: translateY(-50%);
									transition: border .5s ease;
									width: 0;
								}
							}

							&.mod-active {
								&:after {
									border-bottom: 9px solid $color-primary;
									border-left: 5px solid transparent;
									border-right: 5px solid transparent;
									border-top-width: 0;
								}
							}
						}

						.list-sub-nav {
							@include mq(tablet-down) {
								box-shadow: none;
								left: 0;
								max-height: 0;
								margin-top: 0;
								padding: 0;
								position: relative;
								top: 0;
								transition: max-height .3s ease !important;
								visibility: hidden;
								width: 100%;
							}


							&.mod-active {
								@include mq(tablet-down) {
									max-height: 1000px;
									margin-bottom: 20px;
									visibility: visible;
								}
							}
						}

						ul {
							@extend %list-default;

							background: $color-white;
							box-shadow: 0 2px 10px 0 rgba($color-black, 0.25);
							opacity: 0;
							padding: 25px 0 25px 30px;
							position: absolute;
							top: 60px;
							visibility: hidden;
							width: 225px;

							@media screen and (max-width: 1337px) {
								top: 45px;
							}

							@include mq(tablet-down) {
								opacity: 1;
							}


							li {
								display: block;

								a {
									display: block;
									font-size: $font-size-small-med;
									font-weight: $font-weight-regular;
									letter-spacing: normal;
									padding: 10px 0;
									text-transform: unset;

									@include mq(tablet-down) {
										font-weight: $font-weight-semibold;
										padding: 13px 0;
										text-transform: uppercase;
									}
								}
							}
						}

						&.mod-about {
							@include mq(tablet-down) {
								.mod-dropdown {
									height: 0;
									overflow: hidden;
									padding: 0;
								}

								.list-sub-nav {
									max-height: none;
									opacity: 1;
									visibility: visible;

									li a {
										align-self: flex-start;
										display: inline-block;
										font-size: 20px;
										font-weight: $font-weight-bolder;
										padding: 15px 20px 15px 0;
										position: relative;
										width: auto;
									}
								}
							}
						}
					}
				}
			}

			.sub-search {
				align-self: center;
				background: $color-primary;
				border-radius: 50%;
				height: 40px;
				position: relative;
				width: 40px;
				transition: background .3s ease;

				&:hover {
					background: highlight-by($color-primary, 10%);
				}

				@include mq(tablet-down) {
					height: 33px;
					width: 33px;
				}


				&:before {
					background: $color-white;
					bottom: 0;
					content: "";
					height: 2px;
					left: 13px;
					margin: auto;
					position: absolute;
					right: 0;
					top: 9px;
					transform: rotate(45deg);
					transition: all .35s;
					width: 7px;

					@include mq(tablet-down) {
						width: 6px;
						left: 10px;
					}
				}

				&:after {
					border-radius: 50%;
					border: 2px solid $color-white;
					bottom: 0;
					content: "";
					height: 11px;
					left: -1px;
					margin: auto;
					position: absolute;
					right: 0;
					top: -3px;
					transition: all .35s;
					width: 11px;

					@include mq(tablet-down) {
						height: 8px;
						width: 8px;
					}
				}

				&.mod-active {
					&:before {
						left: 0;
						top: 0;
						width: 18px;

						@include mq(tablet-down) {
							width: 16px;
						}
					}

					&:after {
						background: $color-white;
						border-radius: 0%;
						border: none;
						height: 2px;
						left: 0;
						top: 0;
						transform: rotate(-45deg);
						width: 18px;

						@include mq(tablet-down) {
							width: 16px;
						}
					}
				}

				@include mq(tablet-down) {
					position: absolute;
					right: 50px;
					top: -43px;
					visibility: visible;
				}


				.icon-search {
					height: 40px;
					width: 40px;

					@include mq(tablet-down) {
						height: 33px;
						width: 33px;
					}
				}
			}
		}

		.sub-search-bar {
			align-items: center;
			background: rgba($color-white, 0.95);
			border-top: 1px solid $color-borders;
			display: flex;
			height: 0;
			justify-content: center;
			left: 0;
			overflow: hidden;
			position: absolute;
			top: 92px;
			transition: height .3s ease, visibility .3s ease;
			visibility: hidden;
			width: 100%;

			@include mq(tablet-down) {
				padding: 0 35px;
				top: 53px;
			}


			&.mod-show {
				height: auto;
				overflow: visible;
				min-height: 123px;
				visibility: visible;
			}

			.sub-searchform {
				max-width: 500px;
				width: 100%;

				input {
					background: none;
					border: none;
					border-bottom: 1px solid $color-primary;
					border-radius: 0;
					font-size: $font-size-large;
					font-weight: $font-weight-regular;
					padding: 13px 0;
					width: 100%;

					&:focus {
						outline: none;
					}
				}
			}

			.sub-results {
				align-items: center;
				background: rgba($color-white, 0.95);
				display: flex;
				flex-direction: column;
				height: 100vh;
				left: 0;
				position: absolute;
				top: 122px;
				width: 100%;

				.sub-loading {
					display: none;
					position: absolute;
					top: 50px;
				}

				.list-results {
					@extend %list-default;

					align-self: flex-start;
					display: none;
					margin: 0 auto;
					max-width: 500px;
					max-height: 500px;
					padding: 20px 0;
					overflow: scroll;
					width: 84%;

					&.mod-show {
						display: block;
					}

					& > li {
						border-bottom: 1px solid rgba($color-black, 0.25);
						display: flex;

						&:first-child {
							padding-top: 0;
						}

						&:last-child {
							border-bottom: none;
						}

						.title {
							color: $color-text;
							flex: 1;
							padding: 20px 0;

							&:hover {
								color: $color-primary;
							}

							@include mq(mobile) {
								padding-right: 40px;
							}
						}

						.category {
							align-items: center;
							color: $color-primary;
							display: flex;
						}
					}
				}

				.sub-error {
					font-size: $font-size-h3;
					text-align: center;
					width: 100%;

					&.mod-pad {
						padding: 0 0 40px;
					}
				}
			}
		}
	}

	.section-cookies {
		align-items: center;
		background: $color-neutral-dark;
		display: flex;
		min-height: 75px;
		padding: 10px 0;

		@include mq(tablet-down) {
			height: auto;
			padding: 10px 0 20px;
		}


		&.mod-hide {
			display: none;
		}

		.sub-content {
			@extend %container-large;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-text {
				flex: 1;

				& > p {
					color: $color-white;
					font-size: $font-size-small;
					margin-bottom: 0;

					@include mq(tablet-down) {
						color: rgba($color-white, 0.7);
						font-size: $font-size-small;
					}


					a {
						color: $color-white;
						font-weight: $font-weight-semibold;

						@include mq(tablet-down) {
							color: rgba($color-white, 0.7);
						}
					}
				}
			}

			.sub-btns {
				align-items: center;
				display: flex;
				margin-left: 140px;

				@include mq(tablet-down) {
					margin-left: 0;
					margin-top: 10px;
				}


				.btn {
					background: none;
					border: 2px solid rgba($color-white, 0.25);
					color: $color-white;
					display: inline-block;
					font-size: $font-size-standard;
					font-family: $headings-font !important;
					padding: 5px 0;
					transition: background .3s ease;
					width: 130px;

					@include mq(tablet-down) {
						font-size: $font-size-small;
						padding: 4px 0;
						width: 115px;
					}


					&:first-of-type {
						margin-right: 10px;
					}

					&:hover {
						background: rgba($color-white, 0.25);
					}
				}
			}
		}
	}
}
