#articles, #news {
	.section-hero {
		@extend %common-hero-darker;

		display: flex;
		height: 475px;
		position: relative;

		@include mq(tablet-down) {
			height: 415px;
		}


		@include mq(mobile) {
			background-image: none !important;
			flex-direction: column;
			max-width: calc(100% - 20px);
			margin: 0 auto;
		}


		img {
			height: 100%;
			left: auto;
			right: 0;
			width: auto;

			@media screen and (min-width: 1420px) {
				height: auto;
				width: 130vw;
			}

			@include mq(mobile) {
				height: auto;
				position: relative;
				right: 20px;
				top: 0;
				width: 100%;
			}
		}

		&:before {
			content: '';
			background: linear-gradient(270deg, rgba(0, 0, 0, 0.25) 0%, #000000 100%);
			height: 100%;
			left: 0;
			opacity: 0.78;
			position: absolute;
			top: 0;
			width: 100%;

			@include mq(mobile) {
				content: none;
			}
		}

		&:after {
			@extend %diagonal;

			@include mq(tablet-down) {
				content: none;
			}
		}

		.sub-content {
			@extend %container-large;

			@include mq(mobile) {
				margin-top: 6vw;
				max-width: 100%;
			}


			.sub-copy {
				color: $color-white;
				max-width: 500px;
				position: relative;
				z-index: 99;

				@include mq(mobile) {
					color: $color-text;
					max-width: none;
					padding-top: 14px;
					width: 100%;
				}


				h1 {
					color: $color-white;
					font-size: $font-size-hero-small;
					font-weight: $font-weight-bold;
					line-height: 46px;
					margin-bottom: 35px;
					text-transform: unset;

					@include mq(mobile) {
						color: $color-text;
						font-size: $font-size-larger;
						font-weight: $font-weight-bold;
						line-height: $line-height-med;
						margin-bottom: 14px;
					}
				}

				p {
					color: $color-white;
					font-size: $font-size-larger;
					max-width: 350px;
					width: 100%;

					@include mq(mobile) {
						color: rgba($color-text, 0.6);
						display: block !important;
						font-size: $font-size-small-med;
						font-weight: $font-weight-light;
						line-height: $line-height-small;
						margin-bottom: 4px;
						max-width: none;
					}
				}

				a.btn {
					display: block;
					font-size: $font-size-standard;
					margin-top: 40px;

					@include mq(mobile) {
						padding: 18px 0;
						margin-top: 0;
						text-align: left;
					}
				}
			}
		}
	}

	.section-news-list {
		padding: 70px 0 25px;
		position: relative;
		z-index: 99;

		@include mq(mobile) {
			padding: 30px 0 !important;
		}


		.wrap-list {
			@extend %container;

			max-width: 1070px !important;
		}
	}
}

#articles {
	background: $color-body-grey;

	.section-hero {
		&::after {
			background: $color-body-grey;
		}
	}

	.section-news-list {
		padding: 110px 0 150px;
	}
}
