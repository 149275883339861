.section-featured-news {
	overflow-x: hidden;
	padding: 120px 0;

	@include mq(tablet-down) {
		padding: 0 0 75px;
	}


	.sub-content {
		@extend %container;

		overflow: hidden;
		display: flex;

		@include mq(tablet-down) {
			flex-direction: column;
			max-width: none;
		}


		h3 {
			@extend %common-subheading;

			&.mod-mobile {
				display: none;
				max-width: 250px;
				margin-bottom: 35px;
				padding: 0 10px;
			}

			@include mq(tablet-down) {
				display: none;

				&.mod-mobile {
					display: block;
				}
			}
		}

		.sub-post-detail {
			display: flex;
			flex: 1;
			flex-direction: column;
			max-width: 340px;
			margin-right: 120px;
			width: 100%;

			@include mq(tablet-down) {
				margin-right: 0;
				max-width: none;
				order: 1;
				padding: 0 10px;
			}


			.sub-date {
				display: block;
				font-size: $font-size-small;
				margin-bottom: 20px;

				@include mq(tablet-down) {
					margin-bottom: 15px;
					margin-top: 25px;
				}
			}

			h4 {
				font-size: $font-size-larger;
				line-height: $line-height-med;

				@include mq(tablet-down) {
					font-weight: $font-weight-bold;
					margin-bottom: 20px;
				}
			}

			p {
				color: rgba($color-text, 0.6);
				font-size: $font-size-small-med;
				font-weight: $font-weight-light;
				line-height: $line-height-small;
			}

			.btn {
				@include mq(tablet-down) {
					font-size: $font-size-smaller;
					text-align: left;
				}
			}
		}

		.sub-post-image {
			position: relative;
			width: 58%;

			@include mq(tablet-down) {
				width: 100%;
			}
		}
	}

	.list-news {
		@extend %list-default;

		flex-grow: 1;

		.slick-list {
			li {
				margin: 0;
			}
		}
	}

	.list-images {
		@extend %list-default;

		.slick-list {
			.slick-slide {
				&:not(.slick-active) {
					cursor: pointer;
				}

				li {
					margin: 0;

					.sub-image {
						height: 450px;
						width: 100%;

						@include mq(tablet-down) {
							height: 210px;
						}


						img {
							width: 100%;
						}
					}
				}
			}
		}
	}

	ul.slick-dots {
		bottom: 80px;
		position: absolute;
		right: 0;

		@include mq(tablet-down) {
			bottom: auto;
			display: flex;
			position: relative;
			justify-content: center;
			margin-top: 15px;
		}


		li {
			cursor: pointer;

			img {
				display: none;

				&.mod-inactive {
					display: block;
				}
			}

			&.slick-active {
				img {
					&.mod-active {
						display: block;
					}

					&.mod-inactive {
						display: none;
					}
				}
			}
		}
	}
}
