.section-product-interest {
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	height: 486px;
	position: relative;

	@include mq(mobile) {
		background-position: left, top !important;
		background-repeat: no-repeat;
		background-size: 0, cover !important;
		flex-direction: column;
		height: auto;
		padding: 65px 0;
	}


	.sub-content {
		@extend %container;

		align-self: center;

		.sub-copy {
			max-width: 520px;
			width: 100%;

			h3 {
				color: $color-white;
				font-size: $font-size-largest;
				text-transform: uppercase;
				font-weight: $font-weight-black;

				@include mq(tablet-down) {
					font-size: $font-size-h2;
				}


				@include mq(mobile) {
					font-size: $font-size-h3;
					line-height: $line-height-large;
					padding-right: 30px;
				}
			}

			p {
				color: rgba($color-white, 0.5);
				font-size: 20px;
				font-weight: $font-weight-light;
				line-height: $line-height-med;

				@include mq(tablet-down) {
					font-size: $font-size-large;
				}
			}

			.list-contact-details {
				@extend %list-default;

				margin-top: 40px;

				& > li {
					margin-bottom: 20px;

					&:last-child {
						margin-bottom: 0;
					}

					span {
						color: $color-white;
						display: inline-block;
						font-size: 19px;
						font-weight: $font-weight-bolder;
						margin-right: 15px;

						@include mq(mobile) {
							font-size: $font-size-large;
						}
					}

					& > a {
						font-size: 28px;
						font-weight: $font-weight-regular;

						@include mq(mobile) {
							font-size: $font-size-larger;
						}
					}
				}
			}
		}
	}

	.btn {
		align-items: center;
		align-self: center;
		background-color: $color-white;
		color: $color-text-light;
		display: flex;
		font-size: $font-size-small-med;
		font-weight: $font-weight-bolder;
		height: 86px;
		letter-spacing: 4.5px;
		padding: 0 40px;
		position: absolute;
		right: 0;
		text-transform: uppercase;
		top: 50%;
		transform: translateY(-50%);
		transition: background .3s ease;
		width: 366px;

		@include mq(tablet-down) {
			padding: 0 15px;
			width: 230px;

			&:after {
				right: 15px !important;
			}
		}


		@include mq(mobile) {
			font-size: $font-size-small-med;
			height: 70px;
			letter-spacing: 3px;
			margin: 50px auto 20px;
			padding: 0 30px;
			position: relative;
			top: 0;
			transform: none;
			width: 100%;
			width: 95%;

			&:after {
				right: 30px !important;
			}
		}


		&:hover {
			background: highlight-by($color-white, 10%);

			&:after {}
		}

		&:after {
			@extend %caret-right;

			border-bottom-width: 8px;
			border-left-width: 11px;
			border-top-width: 8px;
			right: 40px;
			top: 50%;
			transform: translateY(-50%);
			transition: right .3s ease;

			@include mq(mobile) {
				border-bottom-width: 5px;
				border-left-width: 7px;
				border-top-width: 5px;
				right: 36px;
			}
		}
	}
}
