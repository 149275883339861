#software {
	background: $color-body-grey;

	.section-hero {
		@extend %common-hero-darker;

		&:before {
			background: linear-gradient(90deg, rgba($color-black, 0.55) 50%, transparent);
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&:after {
			background: $color-body-grey;
		}

		.sub-copy {
			position: relative;
			z-index: 999;
		}
	}

	.section-software {
		@extend %container;

		max-width: $mq-tablet-size !important;
		padding: 60px 0 120px;

		@include mq(mobile) {
			padding: 15px 0 80px;
		}


		.sub-filters {
			display: flex;
			margin-bottom: 50px;

			@include mq(mobile) {
				flex-direction: column;
				max-width: 95%;
				margin: 0 auto;
			}


			& > span {
				flex: 0 0 20%;

				@include mq(mobile) {
					flex: 1;
					margin-bottom: 30px;
				}
			}

			ul.list-filter-types {
				@extend %list-default;

				flex: 1;

				& > li {
					display: inline-flex;
					margin-right: 40px;
					position: relative;

					@include mq(mobile) {
						margin-bottom: 25px;
						margin-right: 25px;
					}


					input[type="checkbox"] {
						cursor: pointer;
						height: 100%;
						left: 0;
						opacity: 0;
						position: absolute;
						top: 0;
						width: 100%;
					}

					&:before {
						background-color: $color-white;
						border: 1px solid #c8d2d2;
						box-sizing: border-box;
						content: "";
						display: inline-block;
						height: 20px;
						margin-right: 12px;
						min-width: 20px;
					}

					&:after {
						content: "";
						opacity: 0;
						transition: opacity .3s ease;
					}

					&.on {
						&:after {
							border: solid $color-black;
							border-width: 0 2px 2px 0;
							display: block;
							height: 11px;
							left: 7px;
							opacity: 1;
							pointer-events: none;
							position: absolute;
							top: 1px;
							transform: rotate(45deg);
							width: 5px;
							z-index: 0;
						}
					}
				}
			}
		}

		.list-software {
			@extend %list-default, %table-list;

			position: relative;

			.sub-no-files {
				position: absolute;
				padding-left: 22px;
				top: 60px;
				z-index: -1;

				@include mq(mobile) {
					position: relative;
				}


				p {
					font-size: $font-size-large;
					margin-bottom: 0;
				}

				&:before {
					display: none;
				}
			}

			li {
				&.mod-head {
					@include mq(tablet-down) {
						display: none !important;
					}
				}
			}

			div {
				flex: 0 0 22%;

				@include mq(mobile) {
					display: flex;
					margin-bottom: 10px;

					span {
						flex: 1;
					}
				}


				&:before {
					@include mq(mobile) {
						color: $color-text;
						content: 'Size';
						display: inline-block;
						font-weight: $font-weight-bold;
						width: 120px;
					}
				}

				&:first-child {
					@include mq(mobile) {
						&:before {
							content: 'Resource';
						}
					}


					p {
						margin-bottom: 0;
					}
				}

				&:nth-child(2) {
					flex: 0 0 56%;

					@include mq(mobile) {
						&:before {
							content: 'Description';
						}
					}


					p {
						margin-bottom: 0;
					}
				}

				&:nth-child(3) {
					flex: 0 0 12%;
					text-align: center;

					@include mq(mobile) {
						text-align: left;

						&:before {
							content: 'Version';
						}
					}
				}

				&:last-child {
					flex: 0 0 10%;

					@include mq(mobile) {
						margin-bottom: 0;

						&:before {
							content: 'Size';
						}
					}
				}
			}
		}
	}
}
