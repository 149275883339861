#servicing {
	.section-hero {
		@extend %common-hero-darker;
	}

	.section-copy {
		@extend %two-column;

		padding: 50px 0 10px;

		.sub-copy {
			h2, h3, h4 {
				@extend %common-subheading-modded;
			}

			p {
				font-size: $font-size-large;
				font-weight: $font-weight-light;
				line-height: 28px;
				margin-bottom: 45px;
			}

			ul {
				margin-bottom: 45px;

				li {
					color: rgba($color-text, 0.6);
					font-size: $font-size-small-med;
					line-height: $line-height-med;
				}
			}
		}

		h3 {
			margin-top: 35px;
		}
	}

	.section-calibration-products {
		background: linear-gradient(177.71deg, rgba(235, 235, 238, 0) 0%, #EBEBEE 100%);

		@include mq(tablet-down) {
			background: none;
		}


		.sub-content {
			@extend %three-col-section;

			padding-bottom: 370px;

			@include mq(tablet-down) {
				padding-bottom: 70px;
			}


			.sub-info {
				@extend %arrow-transition;

				color: $color-text-light;
				padding: 0 20px 0 30px;
				margin-right: 120px;
				position: relative;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					margin-right: 0;
					margin-top: 35px;
					padding: 0 20px 0 0;
					position: relative;
				}


				&:after {
					@extend %caret-right;
				}
			}
		}
	}

	.section-workshop {
		@extend %image-overlap-section;

		background: $color-body;
		padding: 0;
		margin: 0;

		&:after {
			content: none;
		}

		.sub-content .sub-copy {
			@include mq(tablet-down) {
				flex-direction: column;
			}


			.sub-text {
				color: $color-text;
				text-transform: uppercase;
			}

			.sub-main-copy {
				color: $color-text;
				flex: none;

				h2 {
					@extend %common-subheading;
				}

				h3, h4 {
					@extend %common-subheading-modded;
				}

				p {
					max-width: 670px;
					margin-bottom: 20px;
				}

				.btn {
					align-self: flex-start;
					display: block;
					padding-left: 40px;
				}
			}
		}
	}

	.section-contact {
		@extend %image-overlap-section;

		background: $color-text;

		@include mq(tablet-down) {
			margin: 60px 0 200px;
		}


		&:before {
			@include mq(mobile) {
				display: none;
			}
		}

		.sub-content {
			.sub-copy .sub-main-copy {
				flex: none;

				h3 {
					font-size: $font-size-h1;
					color: $color-white;
				}

				p {
					max-width: 600px;
				}
			}

			.btn {
				@extend %arrow-transition;

				align-self: flex-start;
				color: $color-white;
				padding: 6px 20px 0 40px;
				position: relative;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					margin-right: 0;
					margin-top: 35px;
					padding: 0 20px 0 0;
				}


				&:after {
					@extend %caret-right;
				}
			}
		}
	}
}
