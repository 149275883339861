ul.list-posts {
	@extend %list-default;

	& > li {
		margin-bottom: 50px;

		@include mq(mobile) {
			flex-direction: column;
			padding: 0 10px;
			margin-bottom: 40px;
		}


		.sub-wrap {
			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}
		}

		.sub-detail {
			flex: 1;
			padding-right: 135px;
			padding-top: 40px;

			@include mq(tablet-down) {
				padding-right: 30px;
			}


			@include mq(mobile) {
				order: 1;
				padding-right: 0;
			}


			.sub-date {
				font-size: $font-size-small;
				margin-bottom: 15px;

				@include mq(mobile) {
					margin-bottom: 5px;
				}
			}

			h3 {
				font-size: $font-size-larger;
				font-weight: $font-weight-bold;
				line-height: $line-height-med;

				@include mq(mobile) {
					margin-bottom: 14px;
				}
			}

			.sub-copy {
				p {
					color: rgba($color-text, 0.6);
					font-size: $font-size-small-med;
					font-weight: $font-weight-light;
					line-height: $line-height-med;

					@include mq(mobile) {
						margin-bottom: 5px;
					}
				}
			}

			.btn {
				margin-top: 25px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					margin-top: 0;
					text-align: left;
				}
			}
		}

		.sub-link {
			height: 350px;
			max-width: 570px;
			width: 100%;

			@media screen and (max-width: 1065px) {
				max-width: 480px;
				min-width: 480px;
			}

			@include mq(tablet-down) {
				height: 280px;
				max-width: 415px;
				min-width: auto;
				width: 100%;
			}


			@include mq(mobile) {
				height: auto;
				max-width: none;
			}
		}

		.sub-image {
			align-items: center;
			display: flex;
			height: 350px;
			justify-content: center;
			max-width: 570px;
			min-width: 570px;
			width: 100%;

			@media screen and (max-width: 1065px) {
				max-width: 480px;
				min-width: 480px;
			}

			@include mq(tablet-down) {
				height: 280px;
				max-width: 415px;
				min-width: auto;
			}


			@include mq(mobile) {
				height: auto;
				max-width: none;
			}


			img {
				height: auto;
				width: 100%;
			}
		}

		&.mod-articles {
			margin-bottom: 0;

			.sub-accordion {
				align-items: center;
				background-color: $color-white;
				cursor: pointer;
				display: flex;
				font-size: $font-size-h4;
				font-weight: $font-weight-semibold;
				height: auto;
				min-height: 50px;
				margin-bottom: 10px;
				padding: 10px;

				.sub-plus {
					align-items: center;
					display: flex;
					height: 20px;
					justify-content: center;
					margin-right: 10px;
					position: relative;
					width: 20px;

					&:before, &:after {
						background-color: $color-primary;
						content: '';
						display: block;
						height: 12px;
						position: absolute;
						width: 2px;
					}

					&:after {
						transform: rotate(90deg);
					}

					&:before {
						opacity: 1;
						transition: opacity .3s;
					}
				}

				&.mod-active {
					.sub-plus:before {
						opacity: 0;
					}
				}
			}

			.sub-wrap {
				background: $color-white;
				margin-bottom: 30px;
				overflow: hidden;
				padding: 20px;
				overflow: hidden;
				display: none;

				@include mq(tablet-down) {
					flex-direction: column;

					.sub-detail {
						margin-top: 30px;
					}
				}
			}

			.sub-content {
				display: flex;

				@include mq(tablet-down) {
					flex-direction: column;
				}
			}

			.sub-detail {
				padding-right: 65px;
				padding-top: 0;

				@include mq(tablet-down) {
					order: 1;
				}


				@include mq(mobile) {
					padding-right: 30px;
				}
			}
		}
	}
}
