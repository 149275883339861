#articles-post, #news-post {
	.section-hero {
		padding: 55px 0;

		&:before, &:after {
			display: none;
		}

		@include mq(mobile) {
			padding: 0 0 15px;
		}


		.wrap-hero {
			@extend %container;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			position: relative;

			@include mq(mobile) {
				flex-wrap: wrap;
			}


			.sub-copy {
				max-width: 500px;
				padding-right: 50px;

				@include mq(tablet) {
					width: 80%;
				}


				@include mq(mobile) {
					display: flex;
					flex-direction: column;
					order: 1;
					max-width: none;
					width: 100%;

					.sub-social {
						display: flex;
						order: 0;
						padding: 10px 0;

						@include mq(mobile) {
							padding: 5px 0;
						}
					}

					.sub-title {
						order: 1;
					}

					.sub-subtitle {
						order: 2;
					}
				}


				h3 {
					color: $color-text;
					font-size: $font-size-large;
					font-weight: $font-weight-light;
					margin: 0 0 25px;

					@include mq(mobile) {
						display: none;
					}
				}

				h1 {
					font-size: $font-size-hero-small;
					font-weight: $font-weight-bold;
					line-height: 46px;
					margin: 0 0 30px;

					@include mq(tablet-down) {
						font-size: $font-size-h2;
					}


					@include mq(mobile) {
						font-size: $font-size-h3;
						line-height: $line-height-large;
						margin-top: 15px;
						margin-bottom: 15px;
					}
				}

				h2 {
					font-size: $font-size-larger;
					line-height: $line-height-large;
				}

				p {
					font-size: $font-size-large;
					font-weight: $font-weight-regular;
					line-height: $line-height-large;
					margin: 0;

					@include mq(mobile) {
						font-size: $font-size-med-large;
					}
				}

				.sub-social {
					cursor: pointer;
					margin-top: 30px;

					@include mq(mobile) {
						position: relative;
						margin-top: 10px;
					}


					.sub-date-mob {
						display: none;

						@include mq(mobile) {
							display: inline-block;
							flex: 1;
							padding-top: 3px;
						}


						h5 {
							margin: 0 20px 0 0;
							font-size: $font-size-med;

							@include mq(mobile) {
								font-size: $font-size-small;
								font-weight: $font-weight-regular;
							}
						}
					}

					ul.list-social {
						@extend %list-default;

						width: 25px;
						overflow: hidden;
						transition: all 0.3s ease;
						display: flex;

						@include mq(mobile) {
							padding: 0;
							overflow: visible;
							width: auto;
						}


						&:hover,
						&:focus {
							width: 100%;

							@include mq(mobile) {
								width: auto;
							}
						}

						li {
							margin: 0 22px 0 0;
							padding: 0;
							display: inline-block;

							&.mod-event {
								@include mq(mobile) {
									display: none;
								}
							}

							.svg-icon {
								transition: color 0.2s ease;
								color: $color-neutral-dark;

								@include mq(mobile) {
									transform: scale(0.7);
								}


								&.icon-social {
									color: $color-primary;
								}

								&:hover {
									color: highlight-by($color-primary, 10%);
								}
							}
						}
					}
				}
			}

			.sub-image {
				height: 350px;
				width: 570px;
				margin: 0 0 0 auto;

				@include mq(mobile) {
					height: 208px;
					margin: 0 0 20px;
					width: 100%;
				}


				ul.list-image {
					@extend %list-default;

					height: 350px;

					@include mq(mobile) {
						height: 208px;
					}


					li.item-image {
						margin: 0;
						height: 350px;

						@include mq(mobile) {
							height: 208px;
						}


						.sub-background {
							height: 350px;
							overflow: hidden;
							position: relative;

							@include mq(tablet-down) {
								height: 280px;
							}


							@include mq(mobile) {
								height: 208px;
							}


							img {
								left: 0;
								position: absolute;
								top: 0;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	.section-post {
		padding: 70px 0 10px;

		@include mq(mobile) {
			padding: 10px 0 20px;
		}


		.sub-content {
			@extend %container, %clearfix;

			max-width: 965px;

			@include mq(mobile) {
				padding: 0 10px;
			}


			.sub-copy {
				h3 {
					@extend %common-subheading;

					font-size: 20px;
					margin-bottom: 20px;

					@include mq(mobile) {
						font-size: $font-size-large;
						margin-bottom: 15px;
					}
				}

				p {
					color: rgba($color-text, 0.6);
					font-size: $font-size-med;
					line-height: $line-height-large;
					margin-bottom: 35px;

					@include mq(mobile) {
						font-size: $font-size-small-med;
					}
				}
			}
		}
	}

	.section-related.post-page {
		padding: 60px 0;
		background-color: rgba($color-neutral-dark, 0.2);

		@include mq(mobile) {
			padding: 60px 0 80px;
		}


		.wrap-related {
			@extend %container;
		}

		h2 {
			margin: 0 0 55px;
			font-size: $font-size-h3;

			@include mq(mobile) {
				margin: 0 0 40px;
			}
		}
	}
}

#articles-post {
	.section-hero {
		background-color: $color-white;

		&:before, &:after {
			content: none;
		}
	}
}
