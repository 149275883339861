#sitemap {
	.section-site-map {
		padding: 50px 0 100px;

		.wrap-content {
			@extend %container;

			.sub-pages {
				margin-top: 50px;

				.list-pages {
					margin-bottom: 30px;
				}
			}
		}
	}
}
