form {
	input[name="Form_Email"] {
		display: none;
		opacity: 0;
		visibility: hidden;
		height: 0;
	}

	.sub-errors {
		color: $color-text;
		display: none;
		margin-bottom: 40px;

		.sub-error {
			font-size: $font-size-med;
			margin-bottom: 10px;
		}

		ul li {
			color: $color-error;
			margin-bottom: 3px;
		}
	}

	.form-element {
		position: relative;
		margin: 30px 0;

		&.error {
			label {
				color: $color-validation-error;
			}
		}

		input, textarea {
			width: 100%;
			border: none;
			border-radius: 0;
			border-bottom: 1px solid #AFB9BF;
			transition: 0.2s ease;
			padding: 0 10px 0 150px;
			font-size: 20px;
			color: $color-text;
			min-height: 50px;
			outline: none;

			@include autocomplete-fill {
				background-color: $color-white;
				box-shadow: 0 0 0px 1000px $color-white inset;
				-webkit-text-fill-color: $color-text;
				transition: background-color 5000s ease-in-out 0s;
			}


			@include mq(mobile) {
				font-size: $font-size-med;
			}


			&:focus {
				border-color: $color-primary;

				+ label {
					color: $color-primary;
					font-size: $font-size-larger;
					font-weight: $font-weight-semibold;
				}

				@include mq(mobile) {
					@include placeholder {
						color: $color-primary;
					}
				}
			}

			@include placeholder {
				color: $color-white;
			}


			@include mq(mobile) {
				font-size: $font-size-med;
				padding: 0;
				text-align: left;

				@include placeholder {
					color: $color-text;
				}
			}
		}

		label {
			font-size: 20px;
			position: absolute;
			bottom: 15px;
			left: 0;
			width: auto;
			display: block;
			transition: 0.2s ease;

			@include mq(mobile) {
				display: none;
			}
		}

		textarea {
			min-height: 50px;

			@include mq(mobile) {
				margin-top: 20px;
				min-height: auto;
			}


			+ label {
				bottom: 20px;
			}
		}
	}

	.btn.btn-primary {
		margin: 25px 0 0;
		padding: 13px 70px;

		@include mq(mobile) {
			font-size: $font-size-small;
			padding: 15px 0;
			width: 100%;
			margin: 20px 0 0;
		}
	}
}

input, select, textarea, button, optgroup {
	font-family: $font-standard !important;
}
