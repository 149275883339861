// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

%diagonal {
	background: $color-white;
	bottom: -5vw;
	content: "";
	height: 9vw;
	position: absolute;
	transform: skewY(-4deg);
	width: 100%;
	z-index: 0;

	@include mq(tablet-down) {
		bottom: -40px;
		content: "";
		height: 100px;
	}


	@include mq(mobile) {
		bottom: -60px;
	}
}

%common-hero {
	background-position: top left;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	height: 368px;
	overflow: hidden;
	position: relative;

	@include mq(tablet-down) {
		height: 280px;
	}


	@include mq(mobile) {
		height: 215px;
	}


	img {
		height: 100%;
		left: 0;
		position: absolute;
		z-index: -1;
		width: auto;

		@media screen and (min-width: 1420px) {
			height: auto;
			width: 100vw;
		}

		@include mq(tablet-down) {
			height: 100%;
			transform: scale(1.4);
			width: auto;
		}
	}

	&:after {
		@extend %diagonal;
	}

	.sub-content {
		@extend %container;

		display: flex;

		@include mq(tablet-down) {
			margin-top: -40px;
		}


		.sub-copy {
			align-self: center;
			color: $color-white;
			max-width: 460px;
			width: 100%;

			h1 {
				color: $color-white;
				font-size: $font-size-hero-med;
				line-height: $font-size-hero-med;
				text-transform: uppercase;

				@include mq(tablet-down) {
					font-size: $font-size-h1;
					line-height: 35px;
					margin-bottom: 0;
					padding-right: 90px;
				}
			}

			p {
				font-size: $font-size-large;
				font-weight: $font-weight-light;
				line-height: $line-height-large;

				@include mq(tablet-down) {
					display: none;
				}
			}
		}
	}

	&-darker {
		@extend %common-hero;

		&:before {
			background: rgba($color-black, 0.3);
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			width: 100%;
		}

		.sub-content {
			position: relative;
			z-index: 99;
		}
	}
}

%two-column {
	@extend %container;

	display: flex;
	padding: 0px 0;

	@include mq(mobile) {
		flex-direction: column;
		padding: 0;
		margin-top: -25px;
	}


	& > .sub-copy {
		flex: 1;
		padding-right: 120px;

		@include mq(tablet-down) {
			padding-right: 40px;
		}


		@include mq(mobile) {
			padding-right: 0;
		}


		h3 {
			@extend %common-subheading;

			font-size: 20px !important;
			margin-bottom: 15px !important;
		}

		p {
			color: rgba($color-text, 0.6);
			font-size: $font-size-small-med;
			line-height: $line-height-med;
		}
	}

	.aside-benefits {
		align-self: flex-start;
		box-shadow: 0 2px 10px 0 rgba($color-black, 0.25);
		display: flex;
		flex: 0 0 433px;
		padding: 30px 25px;

		@include mq(tablet-down) {
			flex: 0 0 50%;
		}


		@include mq(mobile) {
			flex-basis: 100%;
			margin-top: 40px;
			width: 100%;
		}


		ul.list-benefits {
			@extend %list-default;

			& > li {
				display: flex;
				margin-bottom: 35px;

				@include mq(tablet-down) {
					margin-bottom: 25px;
				}


				&:last-child {
					margin-bottom: 0;
				}

				.sub-icon {
					align-self: flex-start;
					margin-right: 20px;

					@include mq(mobile) {
						margin-right: 25px;
						padding-top: 3px;
						width: 65px;

						img {
							width: 65px;
						}
					}
				}

				.sub-copy {
					h4 {
						font-size: 19px;
						font-weight: $font-weight-bold;
						margin-bottom: 10px;

						@include mq(mobile) {
							font-size: $font-size-med;
							margin-bottom: 5px;
						}
					}

					p {
						color: rgba($color-text, 0.6);
						font-size: $font-size-standard;
						line-height: $line-height-small;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

%three-col-section {
	@extend %container;

	display: flex;
	padding: 40px 0 50px 60px;

	&.mod-no-pad {
		padding-left: 0;
	}

	@include mq(tablet-down) {
		flex-direction: column;
		padding: 70px 0 0 0;
	}


	.sub-text {
		padding-right: 85px;

		@include mq(tablet-down) {
			margin-bottom: 20px;
		}


		span {
			display: inline-block;
			font-size: $font-size-largest;
			font-weight: $font-weight-black;
			line-height: $line-height-base;
			max-width: 200px;
			text-transform: uppercase;

			@include mq(tablet-down) {
				font-size: $font-size-h3;
			}
		}
	}

	.sub-main-copy {
		flex: 1;

		@include mq(tablet-down) {
			padding-right: 30px;
		}


		h2 {
			@extend %common-subheading;
		}

		h3, h4 {
			@extend %common-subheading-modded;
		}

		p {
			font-size: $font-size-large;
			font-weight: $font-weight-light;
			line-height: 28px;
			margin-bottom: 20px;
			margin-top: -5px;
		}
	}

	.sub-info {
		align-self: flex-start;
		justify-content: flex-end;
		padding-left: 150px;
	}
}

%table-list {
	width: 100%;

	@include mq(mobile) {
		padding: 0 10px;
	}


	& > li {
		align-items: center;
		display: flex;
		font-size: $font-size-med;
		font-weight: $font-weight-bold;
		height: 50px;
		padding: 0 22px 15px;
		position: relative;

		@include mq(mobile) {
			align-items: flex-start;
			display: none;
		}


		.sub-link {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&:not(.mod-head) {
			background: $color-white;
			font-size: $font-size-standard;
			font-weight: $font-weight-regular;
			margin-bottom: 6px;
			padding-bottom: 0;

			@include mq(mobile) {
				display: flex;
				height: auto;
				flex-direction: column;
				margin-bottom: 10px;
				padding: 25px 20px;
			}


			div:first-child {
				color: $color-primary;
				font-weight: $font-weight-semibold;
			}
		}
	}
}

%image-overlap-section {
	background: $color-text;
	margin: 140px 0 290px;
	padding: 190px 0;
	position: relative;

	@include mq(tablet-down) {
		margin: 0px 0 220px;
		padding: 0;
	}


	&:after, &:before {
		@extend %diagonal;
	}

	&:before {
		height: 11vw;
		top: -90px;
	}

	.sub-content {
		@extend %container;

		.sub-img {
			align-items: start;
			display: flex;
			height: 444px;
			justify-content: center;
			margin-bottom: -300px;
			overflow: hidden;
			position: relative;
			transform: translateY(-300px);
			width: 100%;
			z-index: 99;

			@include mq(tablet-down) {
				height: 210px;
				margin-bottom: 0;
				transform: translateY(0px);
			}


			img {
				width: 100%;
			}

			&.mod-first {
				transform: translateY(-300px);
				margin-bottom: -300px;

				@include mq(tablet-down) {
					margin-bottom: 0;
					margin-top: 0;
					transform: translateY(0px);
				}
			}

			&.mod-last {
				margin-bottom: -340px;
				transform: translateY(0px);

				@include mq(tablet-down) {
					margin-bottom: -60px;
					transform: translateY(60px);
				}
			}
		}

		.sub-copy {
			color: $color-white;
			display: flex;
			padding: 130px 0 75px 60px;

			&.mod-no-pad {
				padding-left: 0;
			}

			@include mq(tablet-down) {
				flex-direction: column;
				padding: 45px 0 0 0;
			}


			.sub-text {
				padding-right: 55px;
				padding-top: 4px;
				text-transform: uppercase;

				@include mq(tablet-down) {
					margin-bottom: 20px;
				}


				span {
					display: inline-block;
					font-size: $font-size-largest;
					font-weight: $font-weight-black;
					line-height: $line-height-larger;
					max-width: 240px;

					@include mq(tablet-down) {
						font-size: $font-size-h3;
						max-width: 200px;
						width: 100%;
					}
				}
			}

			.sub-main-copy {
				flex: 1;

				p {
					font-size: $font-size-large;
					font-weight: $font-weight-light;
					line-height: 28px;
					margin-bottom: 20px;
					max-width: 660px;
					width: 100%;

					@include mq(tablet-down) {
						font-size: $font-size-med-large;
					}
				}
			}
		}
	}
}

%product-listing {
	@extend %list-default;

	align-self: flex-start;
	display: flex;
	flex: 1;
	flex-wrap: wrap;

	@include mq(mobile) {
		justify-content: space-between;
	}


	& > li {
		margin-bottom: 25px;
		margin-right: 3%;
		width: 31.333%;

		@include mq(mobile) {
			width: 48%;
			margin-right: 0;
		}


		&:nth-child(3n+3) {
			margin-right: 0;
		}

		.sub-product-image {
			background: $color-body-grey;
			height: 305px;
			max-width: 305px;
			margin-bottom: 20px;
			width: 100%;

			@include mq(tablet-down) {
				height: 28vw;
				max-width: none;
			}


			@include mq(mobile) {
				height: 47vw;
			}


			& > a {
				align-items: center;
				display: flex;
				height: 100%;
				overflow: hidden;
				justify-content: center;
				width: 100%;

				&:hover {
					img {
						transform: scale(1.05);
					}
				}

				img {
					height: 85%;
					max-height: 280px;
					object-fit: contain;
					transition: all .3s ease;
					width: auto;
				}
			}
		}

		.sub-product-title {
			color: $color-text;
			display: block;
			font-size: $font-size-med;
			font-weight: $font-weight-bolder;
			margin-bottom: 10px;
			text-transform: uppercase;
			transition: color .3s ease;

			&:hover {
				color: $color-primary;
			}
		}

		& > p {
			color: rgba($color-text, 0.6);
			font-size: $font-size-small;
			line-height: $line-height-small;
			max-width: 236px;
			width: 100%;
		}
	}

	.sub-no-products {
		font-size: $font-size-larger;
		font-weight: $font-weight-regular;
		line-height: 38px;
		text-align: center;
		width: 100%;
	}
}

%common-subheading {
	font-size: $font-size-h3;
	font-weight: $font-weight-black;
	max-width: 220px;
	margin-bottom: 45px;
	text-transform: uppercase;

	&-modded {
		@extend %common-subheading;

		font-size: 20px;
		max-width: none;
		margin-bottom: 15px;
	}

	@include mq(tablet-down) {
		font-size: $font-size-larger;
	}
}

%caret-right {
	border-bottom: 5px solid transparent;
	border-left: 7px solid $color-primary;
	border-top: 5px solid transparent;
	content: '';
	height: 0;
	position: absolute;
	right: -4px;
	width: 0;
}

%caret-up {
	border-bottom: 9px solid $color-primary;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	content: '';
	height: 0;
	position: absolute;
	right: -4px;
	transition: border .5s ease;
	width: 0;
}

%caret-down {
	@extend %caret-up;

	border-top: 9px solid $color-primary;
	border-bottom: 0;
}

%arrow-transition {
	transition: color .3s ease;

	&:after {
		transition: right .3s ease;
	}

	&:hover {
		color: $color-primary;

		&:after {
			right: -10px;
		}
	}
}
