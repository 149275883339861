html {
	font-size: $font-size-base;

	body {
		background: $color-body;
		color: $color-text;
		font-family: $font-standard;
		font-size: $font-size-standard;
		font-weight: $font-weight-regular;
		line-height: $line-height-base;
		margin: 0;
		padding-top: 92px;

		@include mq(tablet-down) {
			padding-top: 53px;
		}


		&.no-scroll {
			overflow: hidden;
		}

		a.anchor {
			display: block;
			visibility: hidden;
			position: relative;
			top: -140px; // Usually height of the header
	
		}
	}

	* {
		box-sizing: border-box;
	}

	.grecaptcha-badge {
		visibility: hidden;
	}
}
