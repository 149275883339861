.about {
	.section-hero {
		@extend %common-hero-darker;

		.sub-content .sub-copy h1 {
			padding-right: 170px;
		}
	}

	.section-copy {
		@extend %two-column;

		padding: 50px 0 0;

		.sub-copy img {
			height: auto !important;
			max-width: 100%;
		}

		@include mq(mobile) {
			padding: 0;
		}


		h1, h2, h3, h4 {
			@extend %common-subheading-modded;
		}

		.sub-copy {
			p {
				font-size: $font-size-large;
				font-weight: $font-weight-light;
				line-height: 28px;
				margin-bottom: 20px;
				margin-top: -5px;
			}
		}

		h3 {
			margin-top: 35px;
		}

		.list-timeline {
			list-style: none;
			margin: 0;
			padding: 40px 0 80px;

			@include mq(mobile) {
				padding: 40px 0;
			}


			& > li {
				border-left: 10px solid $color-borders-light;
				padding: 20px 0 35px 50px;
				position: relative;
				margin-bottom: 20px;
				margin-left: 0;

				&:last-child {
					margin-bottom: 0;
					padding-bottom: 0;
				}

				.sub-year {
					color: $color-primary;
					font-size: $font-size-largest;
					font-weight: $font-weight-light;
					position: absolute;
					top: -20px;

					@include mq(mobile) {
						font-size: $font-size-h2;
					}


					&:before {
						background: #979797;
						content: '';
						height: 1px;
						left: -60px;
						position: absolute;
						top: 10px;
						width: 35px;
					}
				}

				p {
					font-size: $font-size-med-large;
					font-weight: $font-weight-light;
					margin-bottom: 0;

					@include mq(mobile) {
						font-size: $font-size-small-med;
						padding-right: 25px;
					}
				}
			}
		}
	}

	.section-riken {
		@extend %three-col-section;

		@include mq(tablet-down) {
			margin-bottom: 70px;

			.sub-info {
				margin: 30px 0 10px;
				padding: 0;
			}
		}
	}

	.section-quality-assurance {
		@extend %image-overlap-section;

		@include mq(tablet-down) {
			margin-bottom: 220px;
		}
	}

	.section-vacancies {
		background: $color-body-grey;
		padding: 80px 0;

		.sub-title {
			@extend %container;

			margin-bottom: 65px;
		}

		.sub-copy {
			@extend %container;

			display: flex;
			flex: 1;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.list-vacancies {
				@extend %list-default;

				padding-right: 120px;

				@include mq(tablet-down) {
					padding-right: 0;
				}


				& > li {
					h3 {
						@extend %common-subheading;

						font-size: 20px;
						max-width: none;
						margin-bottom: 20px;
					}

					p {
						font-size: $font-size-med;
					}
				}
			}

			.aside-apply {
				background: $color-neutral;
				flex: 0 0 350px;
				padding: 50px;
				margin-top: -100px;

				@include mq(mobile) {
					margin-top: 20px;
				}


				h3 {
					@extend %common-subheading;

					font-size: 20px;
					margin-bottom: 20px;
				}

				p {
					font-size: $font-size-med;
				}
			}
		}
	}
}

#history {
	.aside-benefits {
		margin-bottom: 105px;
	}
}
