.products {
	.section-hero {
		@extend %common-hero-darker;
	}

	.section-product-listing {
		padding: 60px 0;

		@include mq(tablet-down) {
			padding: 0 0 60px;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(tablet-down) {
				flex-direction: column;
			}


			.aside-filters {
				flex: 0 0 212px;
				margin-right: 53px;

				@include mq(tablet-down) {
					background: $color-white;
					height: 92vh;
					margin-right: 0;
					overflow: scroll;
					padding: 50px;
					position: absolute;
					right: 100%;
					top: 0;
					transition: right .3s ease;
					width: 100%;
					z-index: 999;

					&.mod-active {
						right: 0%;
					}
				}


				.btn.mod-close {
					background: $color-white;
					bottom: 0px;
					display: none;
					left: 0;
					padding-bottom: 45px;
					position: fixed;
					width: 100%;

					@include mq(tablet-down) {
						&.mod-show {
							display: block;
						}
					}
				}

				h4 {
					align-items: baseline;
					color: $color-primary;
					display: flex;
					font-size: $font-size-large;
					font-weight: $font-weight-bold;
					margin-bottom: 5px;

					span.sub-reset {
						cursor: pointer;
						display: inline-block;
						font-size: $font-size-small;
						flex: 1;
						color: $color-text;
						text-decoration: underline;
						text-align: right;
					}
				}

				.sub-filter-type {
					.sub-filter-type-title {
						border-bottom: 1px solid $color-borders;
						color: $color-text;
						cursor: pointer;
						display: block;
						font-size: $font-size-small-med;
						font-weight: $font-weight-bold;
						margin: 20px 0 10px;
						padding-bottom: 12px;
						position: relative;

						&:after {
							@extend %caret-up;

							right: 10px;
							top: 8px;
						}

						&.mod-hide {
							&:after {
								@extend %caret-down;

								right: 10px;
								top: 8px;
							}

							& + .list-product-type {
								display: none;
							}
						}
					}

					.list-product-type {
						@extend %list-default;

						& > li {
							cursor: pointer;
							padding: 7px 0;
						}
					}
				}
			}

			.list-products {
				@extend %product-listing;
			}
		}

		.sub-filter-btn-mob {
			display: none;
			position: relative;
			padding-right: 20px;

			&:after {
				border-bottom: 0px solid transparent;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 7px solid #eb212e;
				content: "";
				height: 0;
				position: absolute;
				right: 0;
				width: 0;
				top: 10px;
			}

			@include mq(tablet-down) {
				color: $color-text;
				cursor: pointer;
				display: block;
				font-size: $font-size-med;
				font-weight: $font-weight-semibold;
				margin-bottom: 30px;
				margin-top: -25px;
				text-align: right;
				z-index: 99;
			}
		}
	}

	.section-product-detail-info {
		padding: 70px 0;

		@include mq(mobile) {
			padding: 0;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(mobile) {
				flex-direction: column;
				max-width: none;
			}


			.sub-detail-category {
				font-size: $font-size-med;
				font-weight: $font-weight-black;
				line-height: $line-height-large;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-large;
					text-align: left;

					&:not(.mob-mobile) {
						display: none;
					}
				}


				&.mod-mobile {
					@extend %container;

					display: none;

					@include mq(mobile) {
						display: block;
						margin-bottom: 15px;
						margin-top: 15px;
					}
				}
			}

			.sub-main-description {
				flex: 1;
				padding-right: 160px;

				@include mq(tablet-down) {
					padding-right: 90px;
				}


				@include mq(mobile) {
					order: 1;
					padding: 0 ($container-gutter-mobile / 2);
				}


				img {
					height: auto !important;
					max-width: 100%;
				}

				.sub-detail-title {
					font-size: $font-size-largest;
					font-weight: $font-weight-black;
					margin-bottom: 10px;
					text-transform: uppercase;

					@include mq(mobile) {
						font-size: $font-size-h3;
						text-align: center;
					}
				}

				.sub-detail-caption {
					font-size: 16px;
					font-weight: $font-weight-semibold;
					max-width: unset;
					margin-top: 10px;
					text-transform: none;

					@include mq(mobile) {
						text-align: center;
					}
				}

				h3, h4, h5 {
					@extend %common-subheading;

					margin-bottom: 20px !important;
					font-size: 20px !important;
				}

				p {
					color: rgba($color-text, 0.6);
					font-size: $font-size-small-med;
					line-height: $line-height-med;
				}
			}

			.aside-images {
				opacity: 0;
				width: 28%;
				transition: opacity .3s ease;

				&.load {
					opacity: 1;
				}

				@include mq(tablet-down) {
					width: 40%;
				}


				@include mq(mobile) {
					margin-bottom: 50px;
					width: 100%;
				}


				.list-image-gallery, .list-image-gallery-nav, .sub-no-image {
					@extend %list-default;

					background: $color-body-grey;
					height: 336px;
					width: 100%;

					.slick-list, .slick-track {
						height: 100%;
						width: 100%;

						.slick-slide {
							img {
								height: 90%;
								max-width: 100%;
								object-fit: contain;
								width: 90%;

								&.load {
									display: block;
								}

								// @include mq(mobile) {
								// 	width: 45%;
								// }

							}

							& > div {
								height: 100%;
							}
						}
					}

					.sub-product-image {
						align-items: center;
						display: flex !important;
						height: 100%;
						justify-content: center;
						overflow: hidden;
						position: relative;

						@include mq(mobile) {
							background-size: contain;
						}


						.sub-zoom {
							position: absolute;
							bottom: 5px;
							height: 30px;
							right: 5px;
							font-size: 30px;
							transition: all .3s ease;
							width: 30px;

							&:hover {
								transform: scale(1.1);

								&:before {
									border-color: $color-primary;
								}

								&:after {
									background: $color-primary;
								}
							}

							&:before {
								background: transparent;
								border-radius: 50%;
								border: 2px solid rgba($color-text, 0.4);
								content: '';
								display: block;
								height: 15px;
								width: 15px;
							}

							&:after {
								background: rgba($color-text, 0.4);
								bottom: 11px;
								content: '';
								display: block;
								height: 2px;
								position: absolute;
								right: 8px;
								transform: rotate(38deg);
								width: 7px;
							}
						}
					}
				}

				.sub-no-image {
					align-items: center;
					display: flex;
					justify-content: center;
				}

				.list-image-gallery-nav {
					background: none;
					height: 88px;
					margin-top: 10px;
					padding: 0 25px;

					.slick-slide > div {
						padding: 0 5px;
					}

					.sub-product-image {
						background-color: $color-body-grey;
						cursor: pointer;

						img {
							@include mq(mobile) {
								width: 35% !important;
							}
						}
					}
				}
			}

			.sub-image-nav {
				position: relative;

				.sub-prev, .sub-next {
					cursor: pointer;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					z-index: 99;
				}

				.sub-next {
					right: 0;

					@include mq(mobile) {
						right: 10px;
					}


					.svg-icon {
						transform: rotate(180deg);
						transform-origin: center;
					}
				}

				.sub-prev {
					@include mq(mobile) {
						left: 10px;
					}
				}

				.svg-icon {
					height: 10px;
					width: 10px;
				}
			}
		}
	}

	.section-product-features {
		background: $color-body-grey;
		padding: 65px 0 35px;

		h3 {
			@extend %common-subheading;

			max-width: none;
			margin-bottom: 35px;
		}

		.sub-content {
			@extend %container-small;

			.list-product-features {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;

				@include mq(mobile) {
					flex-direction: column;
				}


				& > li {
					flex: 0 0 50%;
					margin-bottom: 30px;

					@include mq(mobile) {
						flex-basis: 100%;
						padding-right: 20px;
					}


					&:nth-child(2n+2) {
						padding-left: 20px;

						@include mq(mobile) {
							padding-left: 0;
						}
					}

					h4 {
						font-size: $font-size-larger;
						font-weight: $font-weight-regular;
						margin-bottom: 10px;
					}

					p {
						color: rgba($color-text, 0.44);
						font-size: $font-size-med-large;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.section-secondary-details {
		@extend %container;

		display: flex;
		padding: 85px 0 55px;

		@include mq(mobile) {
			flex-direction: column;
			padding: 70px 0;
		}


		.sub-copy {
			flex: 1;
			padding-right: 160px;

			@include mq(tablet-down) {
				padding-right: 90px;
			}


			@include mq(mobile) {
				padding-right: 0;
			}


			h3 {
				@extend %common-subheading;

				margin-bottom: 20px !important;
				font-size: 20px !important;
			}

			p {
				color: rgba($color-text, 0.6);
				font-size: $font-size-small-med;
				line-height: $line-height-med;
			}
		}

		.aside-extra-info {
			width: 28%;

			@include mq(tablet-down) {
				width: 40%;
			}


			@include mq(mobile) {
				margin-top: 40px;
				width: 100%;
			}


			h3 {
				@extend %common-subheading;

				font-size: $font-size-large;
				margin-bottom: 30px;
			}

			.list-downloads {
				@extend %list-default;

				& > li {
					background-color: $color-white;
					box-shadow: 0 2px 10px 0 rgba($color-black, 0.25);
					font-size: $font-size-small-med;
					margin-bottom: 12px;

					img {
						margin-right: 20px;
					}

					a {
						align-items: center;
						color: $color-text;
						display: flex;
						height: 70px;
						padding: 0 30px;
					}
				}
			}

			.btn-enquiry {
				align-items: center;
				background: $color-text;
				color: $color-white;
				display: flex;
				font-size: $font-size-smaller;
				font-weight: $font-weight-bolder;
				height: 70px;
				letter-spacing: 3px;
				margin-top: 40px;
				padding: 0 28px;
				position: relative;
				text-transform: uppercase;
				transition: background .3s ease;

				&:hover {
					background: highlight-by($color-text, 10%);

					&:after {
						right: 25px;
					}
				}

				&:after {
					@extend %caret-right;

					right: 28px;
					top: 50%;
					transform: translateY(-50%);
					transition: right .3s ease;
				}
			}
		}
	}

	.section-product-specs {
		@extend %container;

		padding-bottom: 120px;

		h3 {
			@extend %common-subheading;

			font-size: $font-size-larger;
		}

		.sub-wrap {
			position: relative;

			.sub-scroll {
				@include mq(mobile) {
					height: 450px;
					overflow-x: scroll;
				}
			}
		}

		table.sub-spec-table {
			border-collapse: collapse;
			text-align: left;
			width: 100%;

			@include mq(mobile) {
				table-layout: fixed;
			}


			tr {
				&.mod-head {
					th {
						background: $color-neutral-dark;
						border: none;
						color: $color-white;
						font-weight: $font-weight-bold;
						padding: 10px 25px;

						@include mq(mobile) {
							width: 205px;

							&:first-child {
								width: 160px;
							}
						}
					}
				}

				td {
					border: 1px solid rgba($color-text, 0.2);
					padding: 18px 25px;

					&:first-of-type {
						font-weight: $font-weight-bold;

						@include mq(mobile) {
							border-left: 1.5px solid rgba($color-text, 0.35);
						}
					}
				}

				&:last-child td {
					@include mq(mobile) {
						border-bottom-color: rgba($color-text, 0.35);
					}
				}
			}
		}
	}

	&#discontinued {
		background: $color-body-grey;

		.section-hero:after {
			background: $color-body-grey;
		}

		.section-product-listing {
			padding-bottom: 120px;

			.sub-content {
				@extend %container-small;

				.list-discontinued-products {
					@extend %list-default, %table-list;

					& > li {
						height: auto;
						min-height: 50px;

						&.mod-head span {
							&:first-child {
								flex: 0 0 22%;
							}

							&:nth-child(2) {
								flex: 0 0 48%;
							}

							&:last-child {
								flex: 0 0 30%;
							}
						}

						&:not(.mod-head) {
							transition: all .3s ease;

							&:hover {
								box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
							}

							div {
								@include mq(tablet-down) {
									align-items: flex-start;
									display: flex;
									margin-bottom: 15px;
									width: 100%;
								}


								span {

									&:last-child:not(:first-child) {
										margin-left: -3px;

										@include mq(tablet-down) {
											margin: 0;
										}

									}

									a {
										color: $color-text;
										display: inline;
										transition: color .3s ease;

										@include mq(tablet-down) {
											padding-left: 0;
										}


										&:hover {
											color: $color-primary;
										}
									}
								}

								&:before {
									display: inline-block;
									font-size: 14px;
									font-weight: $font-weight-bold;
									line-height: 18px;
									margin-right: 30px;
									min-width: 90px;
									width: 90px;
								}

								&.sub-prod {
									color: $color-text !important;
									flex: 0 0 22%;

									@include mq(tablet-down) {
										&:before {
											content: 'Discontinuted Product';
										}
									}
								}

								&.sub-desc {
									flex: 0 0 48%;
									padding-right: 15px;

									@include mq(tablet-down) {
										padding-right: 0;
										&:before {
											content: 'Description';
										}
									}
								}

								&.sub-replace {
									flex: 0 0 30%;
									position: relative;

									@include mq(tablet-down) {
										&:before {
											content: 'Replacement Product(s)';
										}

										a, span {
											color: $color-primary;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	iframe {
		border: none;
		width: 100%;
	}
}

.loading_overlay {
	background: $color-white;
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 9999;

	img {
		position: absolute;
		top: 50%;
		width: 60px;
	}
}
